import * as React from 'react';

export interface IContextValues {
  readonly checkedValue: string;
  readonly disabled: boolean;
  readonly name: string;
}

export interface IContextActions {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: (value: string) => void;
}

export const ValuesContext = React.createContext<IContextValues>({
  checkedValue: '',
  disabled: false,
  name: '',
});

export const ActionsContext = React.createContext<IContextActions>({
  setDisabled: () => undefined,
  setValue: () => undefined,
});

export interface Props {
  name: string;
  defaultValue?: string;
  disabled: boolean;
  onValueChange?: (value: string) => void;
}

export function Provider({
  name,
  defaultValue,
  disabled,
  onValueChange,
  children,
}: React.PropsWithChildren<Props>) {
  const [checkedValue, setValue] = React.useState(
    defaultValue ? defaultValue : '',
  );
  const [disable, setDisabled] = React.useState(disabled);
  const [commonName] = React.useState(name);

  const valueChangeHandler = (value: string) => {
    setValue(value);
    if (onValueChange) onValueChange(value);
  };

  const ctxValues = { checkedValue, disabled: disable, name: commonName };

  const ctxActions = { setDisabled, setValue: valueChangeHandler };

  React.useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  React.useEffect(() => {
    setValue(defaultValue ? defaultValue : '');
  }, [defaultValue]);

  return (
    <ActionsContext value={ctxActions}>
      <ValuesContext value={ctxValues}>{children}</ValuesContext>
    </ActionsContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
