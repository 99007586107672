import * as React from 'react';
import { OptionInfo } from '../types';

export interface IContextValues {
  readonly isOpened: boolean;
  readonly selectedOption: OptionInfo | undefined;
  readonly options: OptionInfo[];
}

export interface IContextActions {
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOptionState: React.Dispatch<
    React.SetStateAction<OptionInfo | undefined>
  >;
  setOptionsState: React.Dispatch<React.SetStateAction<OptionInfo[]>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  isOpened: false,
  selectedOption: undefined,
  options: [],
});

export const ActionsContext = React.createContext<IContextActions>({
  setOpenState: () => undefined,
  setSelectedOptionState: () => undefined,
  setOptionsState: () => undefined,
});

export interface Props {
  defaultSelectedOption: OptionInfo | undefined;
  defaultOptions: OptionInfo[];
}

export function Provider({
  defaultSelectedOption,
  defaultOptions,
  children,
}: React.PropsWithChildren<Props>) {
  const [isOpened, setOpenState] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOptionState] = React.useState<
    OptionInfo | undefined
  >(
    defaultSelectedOption
      ? defaultSelectedOption
      : (defaultOptions[0] as OptionInfo),
  );

  const [options, setOptionsState] =
    React.useState<OptionInfo[]>(defaultOptions);

  React.useEffect(() => {
    setSelectedOptionState(defaultSelectedOption);
  }, [defaultSelectedOption]);

  const ctxValues = {
    isOpened,
    selectedOption,
    options,
  };

  const ctxActions = {
    setOpenState,
    setSelectedOptionState,
    setOptionsState,
  };

  return (
    <ActionsContext value={ctxActions}>
      <ValuesContext value={ctxValues}>{children}</ValuesContext>
    </ActionsContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
